<template>
    <a href="#" @click.prevent="$root.$children[0].openModal('customer-modal', {customerId:row.item.customer_id}, refreshTable)">
        {{ row.item.customer }}
    </a>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>